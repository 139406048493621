import React from 'react';
import AwardRow from '../components/Accolades/ImageRow';
import Brands from '../components/Brands';
import CTA from '../components/CTA';
import CaseStudies from '../components/CaseStudies';
import Testimonials from '../components/FullTestimonials';
import PageHeader from '../components/PageHeader';
import SEO from '../components/SEO';
import Layout from '../layout';

class Index extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="Testimonials" keywords={['']} pathname={this.props.location.pathname} />
        <PageHeader>
          <h1>Testimonials</h1>
          <h2>Web development teams, developers and designers love Polypane.</h2>
        </PageHeader>
        <Testimonials />
        <CaseStudies />
        <AwardRow />
        <Brands />
        <CTA />
      </Layout>
    );
  }
}

export default Index;
