import { css } from 'astroturf';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

const styles = css`
  .testimonial {
    position: relative;
    text-align: left;
    position: relative;
    margin: 2rem 0;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-content: stretch;
    align-items: stretch;
    scroll-margin-top:100px;

    flex: 1 1 50%;
    &.full {
      flex: 1 0 100%;
    }

    @media (min-width: 770px) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-content: stretch;
      align-items: stretch;
      margin: 2rem auto;

      max-width: 80rem;

      &:nth-child(even) {
        flex-direction: row-reverse;
      }
    }

    & > * {
      flex: 1;
    }

    & blockquote {
      position: relative;
      margin: 0;
      max-width: 40rem;
      padding: 4rem 2rem 2rem;
      @media (min-width: 770px) {
        padding: 2rem 4rem;
      }
    }

    & blockquote::before {
      content: '“';
      font-size: 10rem;
      color: #107db5;
      opacity: 0.15;
      position: absolute;
      top: -2rem;
      left: 2rem;
    }

    & cite {
      justify-content: flex-start;
      align-items: center;
      margin-top: 0;
      max-width: 40rem;
      padding: 0rem 2rem 2rem;
      @media (min-width: 40rem) {
        margin-bottom: 2rem;
        padding: 0rem 4rem;
      }
    }

    & p {
      font-weight: 400;
      font-size: 1rem;
    }

    & strong,
    & b {
      font-weight: 700;
    }
  }
  .logoside {
    display: flex;
    max-width: 40rem;
    padding: 4rem 2rem 2rem;
    @media (min-width: 770px) {
      padding: 2rem 0em;
    }

    & > * {
      margin: auto;
      object-fit: contain;
      max-width: 256px;

      @media (min-width: 400px) {
        max-width: 300px;
      }
    }
  }
  .imgRadius {
    border-radius: 50%;
  }
`;

function Testimonals(props) {

  const slug = props.name ? props.name.replace(/\ /g, "-").toLowerCase() : false;
  return (
    <div id={slug} className={[styles.testimonial, (props.logo || props.svgLogo) && styles.full].join(' ')}>
      <div className={styles.quoteside}>
        <blockquote>{props.quote}</blockquote>
        {props.children}
      </div>
      {(props.logo || props.svgLogo) && (
        <div className={styles.logoside}>
          {props.logo ? (
            <GatsbyImage
              image={props.logo.childImageSharp.gatsbyImageData}
              className={[styles.image, props.radius ? styles.imgRadius : ''].join(' ')}
              alt=""
            />
          ) : (
            ''
          )}
          {props.svgLogo ? (
            <img
              className={[styles.image, props.radius ? styles.imgRadius : ''].join(' ')}
              src={props.svgLogo.publicURL}
              alt=""
            />
          ) : (
            ''
          )}
        </div>
      )}
    </div>
  );
}

export default Testimonals;
