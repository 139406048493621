import { css } from 'astroturf';
import { StaticQuery, graphql } from 'gatsby';
import React from 'react';
import Link from './link';
import Person from './testimonials/FullPerson';
import Testimonial from './testimonials/FullTestimonial';

const styles = css`
  .testimonals {
    position: relative;
    padding: 0rem 0 0;
    text-align: center;
    overflow: hidden;
    max-width: 1200px;
    margin: 0 auto;
    color: #0a1530;

    & h2 {
      font-weight: 900;
      font-size: clamp(18px, 16px + 4vw, 44px);
      letter-spacing: -0.015em;
      line-height: 1.25;
      margin: auto;
      margin-bottom: 0.5rem;
      margin-top: 0;
      letter-spacing: -0.015em;
      max-width: 60rem;
    }

    & p {
      font-size: 1rem;
      font-weight: 400;
      position: relative;
      max-width: 52rem;
      margin: 0 auto 1.5rem;
      line-height: 1.6;
    }

    & a {
      color: #0f7ab2;
    }
  }

  .quotes {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    & i {
      font-size: 14px;
      text-shadow: 0 0 0px #fff;
    }
  }
`;

const hayQuote = (
  <p>
    Designers should confront themselves with the reality of different viewports as early and often as possible.
    <br />
    <br />
    Polypane encourages this behaviour, and makes it easy to do.{' '}
    <strong>It is easily one of the most useful tools for modern web designers and developers.</strong>
  </p>
);

const saraQuote = (
  <p>
    I’ve been using Polypane recently. Not only is it convenient seeing your work in multiple responsive views at the
    same time, but doing that just helped me see a spacing issue between two breakpoints that I wasn’t aware of.{' '}
    <strong>
      Love it, <i>💯</i>
    </strong>
  </p>
);

const kevinQuote = (
  <>
    <p>I've started playing around with Polypane and 🤯.</p>

    <p>
      You can preview multiple screen sizes, all synced. It has{' '}
      <strong>awesome debugging tools, accessibility checks and tests</strong>, and more. I'm really impressed.
    </p>
  </>
);

const graemeQuote = (
  <p>
    I've been using Polypane for Letter, an email tool for designers, and Prototypr 3 - It feels just like
    Chrome/Safari/Firefox and performs just as fast. <strong>It helps me catch issues</strong> and the meta information
    preview is <strong>awesome</strong>. I can test all devices now so don't need to steal my grandma's iPad.
  </p>
);

const sergioQuote = (
  <>
    <p>
      Using Polypane and woah this is awesome! It helps you build responsive apps much easier and it’s{' '}
      <strong>really slick and fast!</strong> I was just 5 minutes in and I already fixed a bug. Holy s--t this is
      awesome.
      <br />
      <br />
      Polypane has become an indispensable tool for my responsive design workflow. Just a bit of honest appreciation:
      excellent work.
    </p>

    <p style={{ paddingTop: '1rem' }}>
      <Link to="/blog/how-sergio-mattei-made-the-next-makerlog-6-x-faster-with-polypane/">
        Read Sergio's full case study
      </Link>
    </p>
  </>
);

const vivianQuote = (
  <>
    <p>
      This thing is FAST and the synchronized scrolling works amazingly. I just love Polypane and love to share it with
      anybody I know. This tool <strong>fits wonderfully with my Tailwind workflow</strong> and I think you will love it
      too. <strong>Prepare to be impressed.</strong>
    </p>
    <p style={{ paddingTop: '1rem' }}>
      <Link to="/blog/how-red-pixel-themes-uses-polypane/">Read Vivian's full case study</Link>
    </p>
  </>
);

const julianQuote = (
  <p>
    Discovering Polypane was <strong>a game-changer</strong> to how I develop responsive websites. Being able to see how
    my site looks at multiple breakpoints offers a completely different perspective to my workflow.{' '}
    <strong>I couldn't imagine going back to using the Chrome inspector.</strong>
  </p>
);

const marcQuote = (
  <p>
    Polypane is <strong>invaluable tool for responsive website testing</strong>. During development, it’s perfect on a
    second monitor to quickly see CSS changes at different screen sizes. And with a full set of dev-tools at hand, it is
    easy to tweak, test, and verify changes quickly. <strong>A must-have for front-end developers.</strong>
  </p>
);
const richardQuote = (
  <p>
    Polypane has made responsive development actually manageable, even for part-time developers like myself. With its
    detection of breakpoints, built in debugging tools, and how it "plays nicely" with other stack elements, it quickly
    became <strong>an indispensable part of my workflow</strong>. With tools like this, you have no excuse not to create
    great, responsive experiences!
  </p>
);
const johnQuote = (
  <p>
    <strong>A vital part</strong> of the web development toolkit for testing and troubleshooting responsive design.
  </p>
);
const nicholasQuote = (
  <>
    <p>
      As a marketing consultant, Polypane has <strong>allowed me to provide better feedback</strong> to our clients and
      prospects by reviewing their websites and landing pages in multiple viewports side-by-side. It has{' '}
      <strong>saved us countless hours</strong> when recording screen share videos, conducting landing page audits, and
      testing campaigns before they go live.
    </p>
    <p style={{ paddingTop: '1rem' }}>
      <Link to="/blog/how-earnworthy-uses-polypane-to-audit-landing-pages-and-land-25-more-customers/">
        Read Nicholas's full case study
      </Link>
    </p>
  </>
);

const nickQuote = (
  <p>
    I was very skeptical at first, but after finding myself annoyed at yet another responsive design, I decided to try
    Polypane. The first half hour in <strong>it solved the frustration I had with responsive design</strong> and I
    enjoyed it a lot. I really like it. Polypane works exactly how you imagine it should.{' '}
    <strong>I recommend it</strong> to anyone developing websites and apps.
  </p>
);

const jarodQuote = (
  <>
    <p>
      Using Polypane for responsive design, accessibility, and emulation, has{' '}
      <strong>saved me countless hours and overhead</strong> building new websites, and working on existing projects.
      The features keep coming and support is hands on.
      <br />
      <br />
      <strong>I highly recommend Polypane for any individual or team</strong> looking for an all-in-one developer
      toolset, with built in prototyping, social media previews, meta info, "polypane" synced editing, and limitless
      bells-and-whistles.
    </p>
  </>
);

const tatendaQuote = (
  <>
    <p>
      At first I didn't really understand why I would need different screen sizes all at once, but{' '}
      <strong>it improved my productivity by a huge factor.</strong>
      <br />
      <br />
      Polypane has greatly cut down the total time I take to convert a mockup to a fully responsive website. It will
      improve your productivity by over 4,000,000%
    </p>
  </>
);

const edgarQuote = (
  <p>
    I've delivered mobile first cross platform software for years without Polypane so I couldn't see any reason to pay
    for something I can do with dev tools in most modern browsers. But I decided to try Polypane because of a tight
    timeline.
    <br />
    <br />
    With Polypane, I was able to{' '}
    <strong>save time by simultaneously building an excellent tablet user experience (without specs!)</strong> in
    addition to a phone & desktop experience that matched their specs perfectly. The value of Polypane definitely
    outweighs the cost. <strong>Get it if you're looking to do more with less time & effort.</strong>
  </p>
);

const daveQuote = (
  <>
    <p>
      <strong>The best productivity tool any developer can use right now is Polypane.</strong> It just keeps getting
      better with every release. My favourite new feature is the Focus Order overview 🔥.
      <br />
      <br />
      Not only that - the accessibility panel now has the WCAG success criteria for issues baked right into it.{' '}
      <strong>I've learned so much about accessibility</strong> through using Polypane.
    </p>
  </>
);

const philippQuote = (
  <>
    <p>
      I was very skeptical if Polypane was worth the money, since I thought of it as &quot;just multiple browser
      windows&quot;. Now I see it as a multitool for web development with tons of features that a traditional browser
      lacks. The multi-window thing is just a bonus!
    </p>
    <p>
      Doing responsive design work has become much easier and{' '}
      <strong>especially the a11y tools are a game-changer</strong>. Following Kilian on Twitter, seeing how active he
      is in discussions around <abbr title="Developer Experience">DX</abbr> and <abbr title="Accessibility">a11y</abbr>,
      gives me huge confidence in the product.
    </p>
  </>
);

const rvxQuote = (
  <>
    <p>
      I was waiting for a proper use case before diving in, but it has made responsive development a whole lot faster.
      The amount of time you can save with this is really nice and that makes it <strong>well worth it</strong>.
    </p>

    <p>
      A week or 2 ago I discovered a bug in Polypane and I wanted to report it. So I contacted support and Kilian
      answered within 2 minutes. <strong>The quick responses and the personal touches</strong> put in the emails is
      really something to admire.
    </p>
  </>
);

const alexQuote = (
  <>
    <p>
      When I first saw Polypane I doubted it could be that much better than Chrome's responsive mode. Let me tell you,{' '}
      <strong>Polypane blows Chrome's responsive mode out of the water</strong>. Being able to work on multiple window
      sizes saves countless hours on every page I build.
    </p>

    <p>
      But having multiple windows is only a fraction of what Polypane offers to improve your websites. The accessibility
      checks, layout debuggers, meta information, and more makes it <strong>an essential tool</strong>.
    </p>

    <p>
      If I'm trying to debug something with my site, there's a good chance Polypane{' '}
      <strong>already has a feature to help me fix it.</strong> It's such a game-changer for how you build websites, I
      recommend it to everyone I talk to.
    </p>
  </>
);

const lucaQuote = (
  <>
    <p>
      I was using other cross-device testing tools, but I was always locked to a single computer. When I found Polypane
      and its multi-device per license package, I was immediately convinced!
    </p>
    <p>
      Polypane <strong>works great both on my desktop and on my laptop</strong> and the synchronized scrolling is just
      awesome!
    </p>
    <p>
      {' '}
      Apart from catching issues and speeding up my development cycle, it also helped me record videos to display the
      same website at different breakpoints, at the same time! <strong>Really excellent piece of software</strong>.
    </p>
  </>
);

const dominicQuote = (
  <>
    <p>
      Using Polypane is an <strong> absolute game changer</strong> whenever you’re designing elements for multiple
      channels. It just completely changes the way you think about making things.{' '}
      <strong>Mobile first? Pfft, how about “everything at the same time”.</strong>
    </p>
    <p>
      I’ll be honest, it’s a bit of a step to make it your default dev browser but once you’ve got it set up and start
      using it you don’t even realise the time you’re saving. It’s just{' '}
      <strong>“oh - I don’t need to test this in a mobile device, coz I already did that when I was making it”.</strong>
    </p>
    <p>
      <strong>The devtools are also a total joy.</strong> Maybe it’s partly because you’re already committed to a large
      screen to get the most out of Polypane, but the stuff you need is VISIBLE without endlessly faffing about with the
      devtools panel. It’s also much <strong>quicker</strong> using tools like React devtools in Polypane.
    </p>
    <p>
      I keep finding stuff which just tickles me - I like the Meta pane because it reminds me to fill that stuff in for
      my own app, as for what it provides me for others.{' '}
      <strong>The built in accessibility checker is one of the best I’ve used BTW</strong> - the way you’ve broken that
      down into Outline/Accessibility/source is really good, and being able to see accessibility issue in the context of
      different device as the same time is <strong>something you don’t realise you need until you use it.</strong>
    </p>
  </>
);

const mikaelQuote = (
  <>
    <p>
      I do pro-bono website design for a number of Danish non-profit social NGOs. They all address users and visitors of
      different ages and abilities - often using outdated PCs and smartphones.
    </p>
    <p>
      Using Polypane to check mobile responsiveness and accessibility issues <strong>has been an eyeopener</strong>{' '}
      improving my designs to meet the requirements of those audiences.{' '}
      <strong>Polypane really is indispensable</strong>.
    </p>
  </>
);

const marcusQuote = (
  <>
    <p>
      <strong>Polypane is the browser me and my team needed</strong>. It merges all the best tools you need as a web
      developer and does this with an instinct & ease of use like no other "multi-pane" browser before.
    </p>
    <p>
      I onboarded my team of 7 developers within 2 days and we never looked back. It's neat that you can even commit &
      push your Workspace together with your project. Make it easier for the team to{' '}
      <strong>instantly get the right setup.</strong>
    </p>
  </>
);

const seanQuote = (
  <>
    <p>
      I love the product. Development is at least <strong>2-3x faster</strong> than before. I'm also finding and fixing
      issues I didn't realize were there, as well as focusing much more on mobile optimization.
    </p>
    <p>
      Polypane truly lives up to its claim of making (responsive) web development much faster - and I'd add{' '}
      <strong>better</strong> as well.
    </p>
  </>
);

const wilQuote = (
  <>
    <p>
      Developing responsive layouts in Polypane <strong>feels like a superpower</strong>. It's rare to find a web
      development tool that has the potential to save this much time.
    </p>

    <p>
      I started using Polypane in hopes of streamlining my process for developing and testing responsive layouts. I'm
      ecstatic to say there has been no more fiddling with Chrome's Device Mode, testing in simulators, or manually
      resizing browser windows. After setting up our web app's breakpoints in Polypane, I can open a single browser
      window and test my layout across all our breakpoints <strong>automagically</strong>.
    </p>

    <p>
      If you design or develop modern web applications,{' '}
      <strong>you're missing out if you haven't given Polypane a try!</strong>
    </p>
  </>
);

const charlieQuote = (
  <>
    <p>
      When I first got Polypane, I thought it was expensive and I'd be ok to do without it to save money - but, I was
      honestly wrong after giving it a try. Since giving it a go,{' '}
      <strong>
        I've been blown away with how well it handles responsive breakpoints, social media previews, site meta tags,
        accessibility and much more.
      </strong>
    </p>

    <p>
      Up until getting Polypane, I never felt I needed to care about accessibility much, it felt difficult reading all
      these guides and I brushed it off. However with Polypane, I just use the tool window which highlights issues and I
      fix them if anything pops up. To me, that is an easy incentive to open the app, check social previews, meta and
      make sure my site has been structured correctly.
    </p>

    <p>
      <strong>Polypane is hands down a tool that I will now recommend to everyone</strong>, you don't realise the time
      you save until you give it a go. It has become the tool I use before I release any website, both personal and for
      any client-related work. There are hundreds of other functionality built-in that I'm yet to use, and that blows me
      away - <strong>I know that when I come across a specific problem, Polypane has my back already.</strong>
    </p>
  </>
);

const andyQuote = (
  <>
    <p>
      I wanted something that <strong>ran lighter than the browser I was using</strong>. Polypane makes my life really
      easy. I like to work with a lot of viewport sizes to make sure my design work looks great across the board.{' '}
      <strong>Polypane runs super fast, so it's a dream.</strong>
    </p>

    <p>
      I like how <strong>easy it is to spin up a viewport</strong>, and quite like having JS and No JS panes running
      side-by-side. Keep doing good things!
    </p>
  </>
);

const kevin2Quote = (
  <>
    <p>
      There's always a learning curve with new software but Polypane makes spotting errors in multiple views easier and
      less stressful. The multi-view layout with synchronized scrolling is the obvious #1 feature.{' '}
      <strong>If Polypane had nothing else, it would still be worth the price</strong> just for this feature alone.
    </p>

    <p>
      I also like the Focus layout that shows one breakpoint at a time, while making it easy to toggle over to whichever
      breakpoint you need, as well as the Workspace breakpoint templates: default and custom breakpoint templates help
      to streamline the dev process, even between team members. Keep up the great work!
    </p>
  </>
);

const julian2Quote = (
  <>
    <p>
      I hesitated getting Polypane because I have tons of tools and didn't want to learn another one. But Polypane has
      made me <strong>much faster and more confident</strong>. I can be confident I'm only changing a particular
      viewport.
    </p>

    <p>
      I like the Tailwind support and that its default viewports are built-in presets. It lead to a better QA process,
      and the support & price point are great.
    </p>
  </>
);

const princeQuote = (
  <>
    <p>
      Seriously love using Polypane 💜. Not only is it <strong>giving so much time back</strong>, it also helps me
      communicate different states to people <strong>with ease</strong>.
    </p>
    <p>No more having to worry if I didn't test it if my default just gives it all to me!</p>
  </>
);

const simonQuote = (
  <>
    <p>
      I’ve been using Polypane for the last few months, and <strong>it’s a total game changer</strong>. It’s super
      helpful seeing your work in multiple responsive views simultaneously;{' '}
      <strong>this saves hours, if not days.</strong>
    </p>
    <p>
      Couple that with the robust accessibility tools and countless other built-in developer tools, and you have{' '}
      <strong>an outstanding product</strong>. I can’t recommend it enough. Bravo!
    </p>
  </>
);

const justinQuote = (
  <>
    <p>
      Polypane revolutionized our QA process, overnight, by reducing our design and layout bugs by 95% and the time
      spent conducting QA by nearly 50%!
    </p>
    <p>
      Put simply; we <b>ship our products faster</b>, with <b>fewer bugs</b>, and <b>spend less money</b> doing so!
    </p>
  </>
);

const sageQuote = (
  <>
    <p>
      I recently started using Polypane to debug responsive views when using Chakra UI. Polypane makes it{' '}
      <strong>so convenient</strong> to debug layouts, perform quick accessibility checks, and emulate different color
      schemes.
    </p>

    <p>
      If you’re designing modern applications and websites,{' '}
      <strong>you should try Polypane! It’s worth every penny!</strong>
    </p>
  </>
);

const davidQuote = (
  <>
    <p>My only review is, "HOLY ****** I've used it everyday since."</p>
  </>
);

const philipQuote = (
  <>
    <p>
      <strong>Polypane is a designers and developers dream.</strong> The synchronised browsing takes the hassle out of
      reviewing design changes and the breakpoint detection is an excellent feature to debug CSS across all media
      queries.{' '}
    </p>
    <p>
      All the browser accessibility is covered from target sizes to contrast checkers, Polypane has it right there in
      the browser. It's amazing! 🤯
    </p>
  </>
);

const larryQuote = (
  <>
    <p>
      As a full-stack hobbyist developer, I was looking for a tool to assist with responsive layouts across many devices
      to deliver a high-quality product for my church. <b>Enter Polypane</b>.
    </p>

    <p>
      <b>WOW</b> - I was highly delighted to find Polypane via a web search.{' '}
      <b>
        Within minutes after installation, I debugged CSS and got consistent, professional-looking results across a
        broad range of devices.
      </b>
    </p>

    <p>
      Polypane is well-designed, making it easy to use and effectively handle the tedious task of addressing responsive
      layouts across MANY device sizes all at once. Additionally, <b>its technical guidance was fantastic</b> and
      allowed me to deliver a professional application.
    </p>
  </>
);

const robQuote = (
  <>
    <p>
      The price 'seemed' a bit high considering my initial use case. Well it turned out{' '}
      <strong>I save around 10 times the monthly cost in testing and building sites.</strong>
    </p>
    <p>
      I have super wide screen monitors so I can have all my breakpoints up on the one screen and also use the social
      and meta data checks. I'd recommend Polypane to anyone.
    </p>
  </>
);

const dashQuote = (
  <>
    <p>
      <strong>Polypane is a giant leap forward in web-development and design work.</strong> Gone are the days of
      fiddling with viewport-resolutions, jumping between various online tools for testing accessibility and repeating
      the same actions to test features on different breakpoints. It's easily <strong>a game-changer</strong>.
    </p>
  </>
);

const mikeQuote = (
  <>
    <p>
      Polypane has made it <strong>dramatically easier to ensure</strong> I'm making accessible designs. To me, all
      those accessibility checks and helpful warnings are the killer feature.
    </p>
    <p>
      <strong>It's hands down the fastest tool I've worked with for spotting issues</strong>. The 'polypane' feature is
      of course also nice. Yes, I'd recommend Polypane!
    </p>
  </>
);

const omriQuote = (
  <>
    <p>
      I have been using Polypane due to the developer focussed design of the app. The options to disable web security
      and testing with CORS disabled <strong>are so useful</strong>.
    </p>
    <p>
      Running multiple viewports in the same view <strong>is more than convenient and it also helps a lot</strong> when
      focusing on accessibility issues.
    </p>
  </>
);

const alexBrianQuote = (
  <>
    <p>
      Polypane has become <b>an invaluable tool</b> for me. <b>It saves me both time and money</b> in various ways. I
      can effortlessly evaluate themes from marketplaces like ThemeForest in Polypane, simultaneously checking desktop
      and mobile views. This has <b>prevented me from making costly theme purchases</b> that might not have suited my
      needs.
    </p>
    <p>
      <b>It keeps getting better</b> with new features added regularly, which is great. Plus, the person who made
      Polypane, Kilian Valkhof, is easy to reach and very helpful.
    </p>
  </>
);

const jeffInnesQuote = (
  <>
    <p>
      I hesitated at first because I wasn't sure I could justify spending money on a tool for testing screen sizes and I
      wasn't convinced it would truly save that much time over traditional testing in dev tools.
    </p>
    <p>
      Polypane has helped ensure the whole team is testing against the same screen sizes through exportable workspaces
      and{' '}
      <b>
        we were able to utilize the <abbr title="Americans with Disabilities Act">ADA</abbr> tools to rapidly analyze
        our design systems compliance and make needed improvements
      </b>
      .
    </p>
    <p>
      <b>You really have to use Polypane to understand how game-changing it is.</b> The website shows what it can do for
      you, but you have to live it to understand.
    </p>
  </>
);

function Testimonals() {
  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => (
        <div className={styles.testimonals}>
          <h2>Here's what they say:</h2>
          <div className={styles.quotes}>
            <Testimonial quote={saraQuote} svgLogo={data.saraLogo} name="Sara Soueidan">
              <Person
                image={data.sara}
                name="Sara Soueidan"
                url="https://www.sarasoueidan.com/"
                desc="Independent front-end UI engineer, Speaker/Trainer"
              />
            </Testimonial>

            <Testimonial quote={kevinQuote} logo={data.kevinLogo} name="Kevin Powell">
              <Person image={data.kevin} name="Kevin Powell" url="https://kevinpowell.co" desc="CSS Evangelist" />
            </Testimonial>

            <Testimonial quote={alexQuote} svgLogo={data.alexLogo} name="Alex Trost">
              <Person
                image={data.alex}
                name="Alex Trost"
                url="https://prismic.io"
                desc={<>Development Experience Engineer at Prismic</>}
              />
            </Testimonial>

            <Testimonial quote={sageQuote} svgLogo={data.sageLogo} name='Segun "Sage" Adebayo'>
              <Person
                image={data.sage}
                name='Segun "Sage" Adebayo'
                url="http://chakra-ui.com/"
                desc="Creator of Chakra UI and Zag.js"
              />
            </Testimonial>

            <Testimonial quote={andyQuote} svgLogo={data.andyLogo} name="Andy Bell">
              <Person
                image={data.andy}
                name="Andy Bell"
                url="https://set.studio/"
                desc={<>Creative Director at Set Creative Studio</>}
              />
            </Testimonial>

            <Testimonial quote={princeQuote} svgLogo={data.princeLogo} name="Prince Wilson">
              <Person
                image={data.prince}
                name="Prince Wilson"
                url="https://netlify.com/"
                desc={<>Senior Software Engineer at Netlify</>}
              />
            </Testimonial>

            <Testimonial quote={vivianQuote} svgLogo={data.vivianLogo} name="Vivian Guillen">
              <Person
                image={data.vivian}
                name="Vivian Guillen"
                url="https://tailwindweekly.com/"
                desc={<>Frontend Developer at Red Pixel Themes &amp; Curator at Tailwind Weekly</>}
              />
            </Testimonial>

            <Testimonial quote={hayQuote} logo={data.stephenLogo} name="Stephen Hay">
              <Person
                image={data.stephen}
                name="Stephen Hay"
                url="http://the-haystack.com/"
                desc="Author of Responsive Design Workflow"
              />
            </Testimonial>

            <Testimonial quote={graemeQuote} svgLogo={data.graemeLogo} name="Graeme Fulton">
              <Person image={data.graeme} name="Graeme Fulton" url="https://letter.so" desc={<>Founder at Letter</>} />
            </Testimonial>

            <Testimonial quote={sergioQuote} svgLogo={data.sergioLogo} name="Sergio Mattei">
              <Person
                image={data.sergio}
                name="Sergio Mattei"
                url="https://getmakerlog.com"
                desc={<>Founder at Makerlog</>}
              />
            </Testimonial>

            {/* <Testimonial quote={julianQuote} logo={data.julianLogo} name="Julian Keenaghan">
              <Person image={data.julian} name="Julian Keenaghan" url="https://wearebeam.org" desc={<>CTO at Beam</>} />
            </Testimonial> */}

            <Testimonial quote={robQuote} svgLogo={data.robLogo} name="Rob Brough">
              <Person
                image={data.rob}
                name="Rob Brough"
                url="https://www.andnow.co.nz"
                desc={<>Cofounder and CTO at AND NOW</>}
              />
            </Testimonial>

            <Testimonial quote={justinQuote} svgLogo={data.justinLogo} name="Justin Wilson">
              <Person
                image={data.justin}
                name="Justin Wilson"
                url="https://arriv.com/"
                desc={<>VP of Engineering at Arriv</>}
              />
            </Testimonial>

            <Testimonial quote={dashQuote} svgLogo={data.dashLogo} name="Dash Donnergren">
              <Person
                image={data.dash}
                name="Dash Donnergren"
                url="https://noaignite.se/"
                desc={<>Senior Front-end Developer at NoA Ignite (Sweden)</>}
              />
            </Testimonial>

            <Testimonial quote={jeffInnesQuote} svgLogo={data.jeffinnesLogo} name="Jeff Innes">
              <Person
                image={data.jeffinnes}
                name="Jeff Innes"
                url="https://grabhosts.net/polypane-review/"
                desc={<>Programming Team Manager at Buffalo Exchange</>}
              />
            </Testimonial>

            <Testimonial quote={alexBrianQuote} svgLogo={data.alexbrianLogo} name="Alex Brian">
              <Person
                image={data.alexbrian}
                name="Alex Brian"
                url="https://grabhosts.net/polypane-review/"
                desc={<>Blogger at Grabhosts</>}
              />
            </Testimonial>

            <Testimonial quote={mikeQuote} svgLogo={data.mikeLogo} name="Mike Cronin">
              <Person
                image={data.mike}
                name="Mike Cronin"
                url="https://www.marcylabschool.org"
                desc={<>Director of Curriculum at The Marcy Lab School</>}
              />
            </Testimonial>

            <Testimonial quote={omriQuote} svgLogo={data.omriLogo} name="Omri Katz">
              <Person
                image={data.omri}
                name="Omri Katz"
                url="https://payzen.com/"
                desc={<>Senior Frontend Developer at PayZen</>}
              />
            </Testimonial>

            {/* <Testimonial quote={nicholasQuote} logo={data.nicholasLogo} name="Nicholas Scalice">
              <Person
                image={data.nicholas}
                name="Nicholas Scalice"
                url="https://earnworthy.com/"
                desc={<>Founder at Earnworthy</>}
              />
            </Testimonial> */}

            <Testimonial quote={philipQuote} svgLogo={data.philipLogo} name="Philip Abbott">
              <Person
                image={data.philip}
                name="Philip Abbott"
                url="https://cafod.co.uk"
                desc={<>Digital Project Manager at CAFOD</>}
              />
            </Testimonial>

            <Testimonial quote={jarodQuote} logo={data.jarodLogo} name="Jarod Thornton">
              <Person
                image={data.jarod}
                name="Jarod Thornton"
                url="http://jarodthornton.com/"
                desc={<>Project Manager at Adopt the Web </>}
              />
            </Testimonial>

            <Testimonial quote={daveQuote} svgLogo={data.daveLogo} name="Dave Davies">
              <Person image={data.dave} name="Dave Davies" url="" desc={<>Frontend Developer at Zengenti</>} />
            </Testimonial>

            <Testimonial quote={lucaQuote} svgLogo={data.lucaLogo} name="Luca Nardelli">
              <Person
                image={data.luca}
                name="Luca Nardelli"
                url="https://sungazer.io"
                desc={<>Full Stack Developer & Founder at Sungazer</>}
              />
            </Testimonial>

            <Testimonial quote={dominicQuote} svgLogo={data.dominicLogo} name="Dominic Jones">
              <Person
                image={data.dominic}
                name="Dominic Jones"
                url="https://cloudthing.com"
                desc={<>UX Engineer at cloudThing</>}
              />
            </Testimonial>

            <Testimonial quote={kevin2Quote} svgLogo={data.kevin2Logo} name="Kevin Bubacz">
              <Person
                image={data.kevin2}
                name="Kevin Bubacz"
                url="https://atlanticbt.com"
                desc={<>Front End Developer at Atlantic BT</>}
              />
            </Testimonial>

            <Testimonial quote={davidQuote} svgLogo={data.davidLogo} name="David Fitzgibbon">
              <Person
                image={data.david}
                name="David Fitzgibbon"
                url="https://lofi.codes/"
                desc={<>Head of Product Development at Mediahuis IRL</>}
              />
            </Testimonial>

            <Testimonial quote={julian2Quote} svgLogo={data.julian2Logo} name="Julian H.">
              <Person name="Julian H." url="https://thisisdare.com" desc={<>Full Stack Developer at Dare</>} />
            </Testimonial>

            {/* <Testimonial quote={nickQuote} svgLogo={data.nickLogo} name="Nick van den Berg">
              <Person
                image={data.nick}
                name="Nick van den Berg"
                url="https://www.handoff.design"
                desc={<>Founder at Handoff.design</>}
              />
            </Testimonial> */}

            <Testimonial quote={wilQuote} svgLogo={data.wilLogo} name="Wil Hall">
              <Person
                image={data.wil}
                name="Wil Hall"
                url="https://wilhall.com/"
                desc={<>Senior Accessibility Web Developer at Sagewell Financial</>}
              />
            </Testimonial>

            <Testimonial quote={edgarQuote} logo={data.edgarLogo} name="Edgar Muentes">
              <Person
                image={data.edgar}
                name="Edgar Muentes"
                url="https://bit.ly/Edgar-Muentes"
                desc={<>Rich Media Architect at Havas Mango</>}
              />
            </Testimonial>

            <Testimonial quote={marcusQuote} svgLogo={data.marcusLogo} name="Marcus Pohorely">
              <Person image={data.marcus} name="Marcus Pohorely" url="https://probots.io" desc={<>CEO at Probots</>} />
            </Testimonial>

            <Testimonial quote={charlieQuote} svgLogo={data.charlieLogo} name="Charlie Joseph">
              <Person
                image={data.charlie}
                name="Charlie Joseph"
                url="https://octanna.com/"
                desc={<>Founder at Octanna</>}
              />
            </Testimonial>

            <Testimonial quote={simonQuote} svgLogo={data.simonLogo} name="Simon Norman">
              <Person
                image={data.simon}
                name="Simon Norman"
                url="https://elevationchurch.org/"
                desc={<>App Developer, Elevation Church</>}
              />
            </Testimonial>

            <Testimonial quote={seanQuote} svgLogo={data.seanLogo} name="Sean Leary">
              <Person
                image={data.sean}
                name="Sean Leary"
                url="https://www.ripcorddesign.com"
                desc={<>Managing Director at Ripcord Design</>}
              />
            </Testimonial>

            <Testimonial quote={larryQuote} svgLogo={data.larryLogo} name="Larry Walter">
              <Person
                image={data.larry}
                name="Larry Walter"
                url="https://foundrychurch.net"
                desc={<>Hobbyist Developer</>}
              />
            </Testimonial>

            <Testimonial quote={mikaelQuote} svgLogo={data.mikaelLogo} name="Mikael Hansen">
              <Person
                image={data.mikael}
                name="Mikael Hansen"
                url="https://www.linkedin.com/in/mhconsult/"
                desc={<>Non-profit consulting</>}
              />
            </Testimonial>

            <Testimonial quote={richardQuote} svgLogo={data.richardLogo} name="Richard Goodwin">
              <Person
                image={data.richard}
                name="Richard Goodwin"
                url="https://comedywham.com/"
                desc={<>Developer &amp; Podcaster at Comedywham</>}
              />
            </Testimonial>

            <Testimonial
              svgLogo={data.JaiLogo}
              name="Jai Sandhu"
              quote={
                <p>
                  I didn't think it would be versatile but Kilian has considered everything. I get{' '}
                  <b>twice as much work done</b> in the same amount of time. <br />
                  <br />
                  Well, twice if I have two viewports open, <b>3x if I have three!</b> Polypane makes development easier
                  and more accurate.
                </p>
              }
            >
              <Person name="Jai Sandhu" url="http://jaisand.hu/" desc={<>Freelance digital art director</>} />
            </Testimonial>

            <Testimonial svgLogo={data.phillipLogo} quote={philippQuote} name="Philipp Nowinski">
              <Person
                image={data.phillip}
                name="Philipp Nowinski"
                url="https://www.pno.dev/"
                desc={<>Founder and Developer at pno</>}
              />
            </Testimonial>

            <Testimonial
              name="Warren Groom"
              quote={
                <p>
                  I didn't think that it would offer me more than the browser I was using but{' '}
                  <b>it's sped up my development time</b> not just in terms of speed of coding & cross-device testing
                  but it's also allowing me to manage accessibility issues real-time and view meta data and snippet data
                  real-time.
                  <br />
                  <br />
                  It allows you to see so much more behind-the-scenes data real-time during development.{' '}
                  <b>It's a coders dream.</b>
                </p>
              }
            >
              <Person
                image={data.warren}
                name="Warren Groom"
                url="https://warrengroom.com"
                desc={<>Freelance WordPress Developer</>}
              />
            </Testimonial>

            <Testimonial quote={rvxQuote} name="RVxLab">
              <Person name="RVxLab" desc={<>Full-stack developer</>} />
            </Testimonial>

            <Testimonial quote={marcQuote} name="Marc Berger">
              <Person image={data.marc} name="Marc Berger" url="" desc={<>Web Developer</>} />
            </Testimonial>

            <Testimonial quote={johnQuote} name="John Camacho">
              <Person image={data.john} name="John Camacho" url="" desc={<>Web Developer at Pixelstation</>} />
            </Testimonial>
          </div>
        </div>
      )}
    />
  );
}

export default Testimonals;

const detailsQuery = graphql`
  query FullTestimonalsQuery {
    stephen: file(relativePath: { eq: "images/hay.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    stephenLogo: file(relativePath: { eq: "images/resdeswork.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 128, quality: 90, layout: FIXED)
      }
    }
    sara: file(relativePath: { eq: "images/sara.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    saraLogo: file(relativePath: { eq: "images/sara.svg" }) {
      publicURL
    }
    kevin: file(relativePath: { eq: "images/kevin.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    kevinLogo: file(relativePath: { eq: "images/kevinlogo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 128, quality: 90, layout: FIXED)
      }
    }
    alex: file(relativePath: { eq: "images/alex.png" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    alexLogo: file(relativePath: { eq: "images/alexlogo.svg" }) {
      publicURL
    }
    graemeLogo: file(relativePath: { eq: "images/letter.svg" }) {
      publicURL
    }
    graeme: file(relativePath: { eq: "images/graeme.png" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    sergioLogo: file(relativePath: { eq: "images/makerlog.svg" }) {
      publicURL
    }
    sergio: file(relativePath: { eq: "images/sergio.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    vivianLogo: file(relativePath: { eq: "images/tailwindweekly.svg" }) {
      publicURL
    }
    vivian: file(relativePath: { eq: "images/vivian.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    dave: file(relativePath: { eq: "images/dave.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    daveLogo: file(relativePath: { eq: "images/brands/zengenti.svg" }) {
      publicURL
    }
    phillip: file(relativePath: { eq: "images/phillip.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    phillipLogo: file(relativePath: { eq: "images/phillipLogo.svg" }) {
      publicURL
    }
    colm: file(relativePath: { eq: "images/colm.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    colmLogo: file(relativePath: { eq: "images/modulz.svg" }) {
      publicURL
    }
    julian: file(relativePath: { eq: "images/julian.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    julianLogo: file(relativePath: { eq: "images/beam.png" }) {
      childImageSharp {
        gatsbyImageData(height: 128, quality: 90, layout: FIXED)
      }
    }
    roy: file(relativePath: { eq: "images/roy.png" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    royLogo: file(relativePath: { eq: "images/roylogo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 128, quality: 90, layout: FIXED)
      }
    }
    tatenda: file(relativePath: { eq: "images/tatenda.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    tatendaLogo: file(relativePath: { eq: "images/tatendalogo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 128, quality: 90, layout: FIXED)
      }
    }
    edgar: file(relativePath: { eq: "images/edgar.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    edgarLogo: file(relativePath: { eq: "images/edgarlogo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 250, quality: 90, layout: FIXED)
      }
    }
    warren: file(relativePath: { eq: "images/warren.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    marc: file(relativePath: { eq: "images/marc.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    richard: file(relativePath: { eq: "images/richard.png" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    richardLogo: file(relativePath: { eq: "images/comedywham.svg" }) {
      publicURL
    }
    JaiLogo: file(relativePath: { eq: "images/jai.svg" }) {
      publicURL
    }
    john: file(relativePath: { eq: "images/john.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    nicholas: file(relativePath: { eq: "images/nicholas.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    nicholasLogo: file(relativePath: { eq: "images/earnworthy.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100)
      }
    }
    nick: file(relativePath: { eq: "images/nick.png" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    nickLogo: file(relativePath: { eq: "images/handoff.svg" }) {
      publicURL
    }
    jarod: file(relativePath: { eq: "images/jarod.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    jarodLogo: file(relativePath: { eq: "images/adoptheweb.png" }) {
      childImageSharp {
        gatsbyImageData(width: 128, quality: 90, layout: FIXED)
      }
    }
    luca: file(relativePath: { eq: "images/testimonials/luca.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    lucaLogo: file(relativePath: { eq: "images/testimonials/sungazer-logo.svg" }) {
      publicURL
    }
    dominic: file(relativePath: { eq: "images/testimonials/dominic.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    dominicLogo: file(relativePath: { eq: "images/testimonials/cloudthing.svg" }) {
      publicURL
    }
    mikael: file(relativePath: { eq: "images/testimonials/mikael.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    mikaelLogo: file(relativePath: { eq: "images/testimonials/rkg.png" }) {
      publicURL
    }
    sean: file(relativePath: { eq: "images/testimonials/sean.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    seanLogo: file(relativePath: { eq: "images/testimonials/ripcord.png" }) {
      publicURL
    }
    marcus: file(relativePath: { eq: "images/testimonials/marcus.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    marcusLogo: file(relativePath: { eq: "images/testimonials/probots.jpg" }) {
      publicURL
    }
    wil: file(relativePath: { eq: "images/testimonials/wil.png" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    wilLogo: file(relativePath: { eq: "images/testimonials/sagewell.png" }) {
      publicURL
    }
    charlie: file(relativePath: { eq: "images/testimonials/charlie.webp" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    charlieLogo: file(relativePath: { eq: "images/testimonials/octanna.svg" }) {
      publicURL
    }
    andy: file(relativePath: { eq: "images/testimonials/andy.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    andyLogo: file(relativePath: { eq: "images/testimonials/andylogo.svg" }) {
      publicURL
    }
    kevin2: file(relativePath: { eq: "images/testimonials/kevin2.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    kevin2Logo: file(relativePath: { eq: "images/testimonials/atlanticbt.png" }) {
      publicURL
    }
    julian2: file(relativePath: { eq: "images/testimonials/kevin2.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    julian2Logo: file(relativePath: { eq: "images/testimonials/dare.svg" }) {
      publicURL
    }
    simon: file(relativePath: { eq: "images/testimonials/simon.png" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    simonLogo: file(relativePath: { eq: "images/testimonials/simonlogo.png" }) {
      publicURL
    }
    prince: file(relativePath: { eq: "images/testimonials/prince.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    princeLogo: file(relativePath: { eq: "images/brands/netlify.svg" }) {
      publicURL
    }
    justin: file(relativePath: { eq: "images/testimonials/justin.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    justinLogo: file(relativePath: { eq: "images/brands/arriv.svg" }) {
      publicURL
    }
    sage: file(relativePath: { eq: "images/testimonials/sage.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    sageLogo: file(relativePath: { eq: "images/testimonials/chakraui.svg" }) {
      publicURL
    }
    philip: file(relativePath: { eq: "images/testimonials/philip.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    philipLogo: file(relativePath: { eq: "images/testimonials/cafod.png" }) {
      publicURL
    }
    david: file(relativePath: { eq: "images/testimonials/david.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    davidLogo: file(relativePath: { eq: "images/testimonials/mediahuis.svg" }) {
      publicURL
    }
    larry: file(relativePath: { eq: "images/testimonials/larry.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    larryLogo: file(relativePath: { eq: "images/testimonials/foundrychurch.png" }) {
      publicURL
    }
    rob: file(relativePath: { eq: "images/testimonials/rob.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    robLogo: file(relativePath: { eq: "images/testimonials/andnow-logo.svg" }) {
      publicURL
    }
    dash: file(relativePath: { eq: "images/testimonials/dash.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    dashLogo: file(relativePath: { eq: "images/testimonials/noa.svg" }) {
      publicURL
    }

    mike: file(relativePath: { eq: "images/testimonials/mike.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    mikeLogo: file(relativePath: { eq: "images/testimonials/marcylogo.png" }) {
      publicURL
    }

    omri: file(relativePath: { eq: "images/testimonials/omri.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    omriLogo: file(relativePath: { eq: "images/testimonials/payzen.svg" }) {
      publicURL
    }
    alexbrian: file(relativePath: { eq: "images/testimonials/alex.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    alexbrianLogo: file(relativePath: { eq: "images/testimonials/grabhost.png" }) {
      publicURL
    }

    jeffinnes: file(relativePath: { eq: "images/testimonials/jeffinnes.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    jeffinnesLogo: file(relativePath: { eq: "images/testimonials/buffaloexchange.svg" }) {
      publicURL
    }
  }
`;
